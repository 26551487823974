/* eslint-disable jsx-a11y/media-has-caption */
import type { FC, ReactNode } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Link,
  Grid,
  Card,
  Avatar,
  Theme,
  useMediaQuery
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { KumaoTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface PromotionVideoProps {}

const PromotionVideo: FC<PromotionVideoProps> = () => {
  const { settings } = useSettings();
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const CustomIcon = (value: { icon: ReactNode }) => {
    const { icon } = value;
    return (
      <Avatar
        sx={{
          height: 42,
          width: 42,
          margin: 0.9,
          bgcolor: 'white',
          color: 'orange'
        }}
      >
        {icon}
      </Avatar>
    );
  };

  return (
    <Box sx={{ backgroundColor: (theme: KumaoTheme) => theme.palette.background.mintgreen }}>
      <Container
        maxWidth={settings.compact ? 'lg' : false}
        sx={{ pt: 1, pb: 5 }}
      >
        <Box mt={3}>
          <Typography
            variant="h4"
            align="center"
            sx={{ color: (theme: KumaoTheme) => theme.palette.primary.main }}
          >
            So funktioniert&apos;s
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ pt: 3 }}
        >
          <Grid
            item
            sm={12}
            md={6}
          >
            <Card
              color="primary"
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                px: 3,
                pt: 1,
                backgroundColor: (theme: KumaoTheme) => theme.palette.secondary.light,
                borderRadius: 5,
                backgroundImage: 'url(/static/home-new/Kumao_24-08_Elemente-02_300ppi.png)',
                backgroundPosition: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 120%',
                minHeight: 415,
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
              }}
            >
              <Box sx={{ my: 1, mx: 'auto', p: 2 }}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <CustomIcon icon={<BusinessIcon />} />
                  </Grid>
                  <Grid
                    item
                    xs
                    zeroMinWidth
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontWeight: 700
                      }}
                    >
                      Präsentiere Dein Unternehmen
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontWeight: 700
                      }}
                    >
                      In nur wenigen Minuten zum Internetaufritt
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  my: 1,
                  mx: 'auto',
                  p: 2
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <CustomIcon icon={<VolunteerActivismIcon />} />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontWeight: 700
                      }}
                    >
                      Beschreibe Deine Leistungen
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontWeight: 700
                      }}
                    >
                      Und erhalte rund um die Uhr Buchungen von Stamm- und Neukunden für Deine Dienstleistungen.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  my: 1,
                  mx: 'auto',
                  p: 2,
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <CustomIcon icon={<CalendarMonthIcon />} />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ my: 'auto' }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontWeight: 700
                      }}
                    >
                      Effiziente Terminplanung mit individuellem Buchungsprozess
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontWeight: 700
                      }}
                    >
                      Nutze optimal Deine vorhandene Zeit.
                      Weniger Aufwand bei der Terminfindung und mehr Zeit für Dein Kerngeschäft.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
          {/* ---------------- second card -------------- */}
          <Grid
            item
            sm={12}
            md={6}
          >
            <Card
              color="primary"
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                px: 3,
                pt: 1,
                backgroundColor: (theme: KumaoTheme) => theme.palette.primary.main,
                borderRadius: 5,
                ...(!sm && { minHeight: 415 }),
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
              }}
            >
              <Box
                sx={{ width: '100%', margin: 'auto' }}
              >
                <Box
                  mt={3}
                  justifyContent="center"
                  sx={{
                    backgroundColor: (theme: KumaoTheme) => theme.palette.background.dark,
                    height: 295
                  }}
                >
                  <video
                    height="295"
                    controls
                    loop
                    autoPlay
                    muted
                  >
                    <source
                      src="/static/home-new/Kumao_Info_Video.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Box>
              </Box>
              <Box
                mt={3}
                pb={3}
                sx={{ textAlign: 'center' }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/register"
                  underline="none"
                >
                  <Button
                    variant="contained"
                    sx={{ minWidth: '80%', borderRadius: 5 }}
                    color="secondary"
                    size="large"
                    startIcon={<PersonIcon />}
                  >
                    Erstelle jetzt Dein kostenloses Konto auf Kumao
                  </Button>
                </Link>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PromotionVideo;
